import React, { Component } from "react";
import Chart, { seriesName, selectColor, approximationMax } from "./Chart.js";

export default class SolarRadiationAccumulationChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      failed: false,
      noData: false,

      dailyData: null,

      options: {
        title: {
          text: "Weekly Solar Radiation Accumulation"
        },
        yAxis: [
          {
            title: {
              text: "Solar Radiation (MJ/m^2)"
            },
            lineWidth: 1,
            gridLineWidth: 0,
            tickWidth: 1,
            tickLength: 5,
            opposite: false,
            min: 0
          }
        ]
      }
    };
  }

  componentDidMount() {
    this.loadChart();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.stopDate !== this.props.stopDate ||
      prevProps.stationId !== this.props.stationId
    ) {
      this.loadChart();
    }
  }

  loadChart() {
    this.setState({ loading: true, noData: false, failed: false }, () => {
      fetch(
        process.env.REACT_APP_METWATCH_URL +
          "index.php" +
          "?pageID=api_far" +
          "&pageNO=dailyComparison" +
          "&stationId=" +
          this.props.stationId +
          "&variableId=SE" +
          "&measTypeId=T" +
          "&seasonStart=" +
          encodeURIComponent(this.props.seasonStart.format()) +
          "&seasonStop=" +
          encodeURIComponent(this.props.seasonStop.format()) +
          "&accumulate=on" +
          "&seasonalAverage=on"
      )
        .then(res => {
          if (res.ok) return res.json();
        })
        .then(json => {
          if (json) {
            this.setState({ dailyData: json }, () => {
              this.initializeChart();
            });
          } else {
            this.setState({ loading: false, failed: true });
          }
        })
        .catch(error => {
          this.setState({ loading: false, failed: true });
        });
    });
  }

  initializeChart() {
    if (!this.state.dailyData) return false;
    let noData = true;

    this.setState({
      options: {
        ...this.state.options,
        series: [
          ...Object.keys(this.state.dailyData).map(year => {
            return {
              name: seriesName(year),
              type: "line",
              data: this.state.dailyData[year]
                ? this.state.dailyData[year].data.SE.T.map(data => {
                    if (data.status !== "missing") noData = false;
                    return data.accumulation;
                  })
                : [],
              dataGrouping: {
                approximation: approximationMax
              },
              color: selectColor(
                year,
                this.props.seasonStart,
                this.props.graphOptions
              ),
              lineWidth:
                parseInt(year) === this.props.seasonStart.year() ? 5 : 2,
              pointStart: new Date(this.props.seasonStart).getTime()
            };
          })
        ]
      },
      loading: false,
      noData: noData,
      failed: false
    });
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        loading={this.state.loading}
        failed={this.state.failed}
        noData={this.state.noData}
      />
    );
  }
}
