import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import StationSelect from "../StationSelect.js";
import "./header.scss";

class Header extends Component {
  render() {
    return (
      <div id="header">
        <Container>
          <Row className="align-items-center justify-content-center py-1">
            <Col xs={5} sm={4} md={3} lg={3} xl={3} className="py-1">
              <img
                src="far-logo.png"
                alt="logo"
                style={{ width: "100%", maxWidth: 150 }}
              />
            </Col>
            <Col xs={7} sm={8} md={8} className="d-md-none text-right">
              <Button
                className="text-decoration-none"
                variant="link"
                href={process.env.REACT_APP_FAR_URL}
              >
                Return to FAR{" "}
                <span className="d-none d-sm-inline-block text-right">
                  website
                </span>
              </Button>
            </Col>
            <Col xs={12} sm={12} md={5} lg={6} xl={6} className="text-center">
              <div className="page-title h3">Weather Summary Tool</div>
            </Col>
            <Col xs={10} sm={8} md={4} lg={3} xl={3} className="pb-3">
              <Button
                className="text-decoration-none d-none d-md-block"
                variant="link"
                href={process.env.REACT_APP_FAR_URL}
              >
                Return to FAR website
              </Button>
              <StationSelect
                selectedStation={this.props.selectedStation}
                setStation={this.props.setStation}
                onboarding={this.props.onboarding}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Header;
