// Polyfills
import "core-js/stable";
import 'whatwg-fetch'

// React
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Momentjs
import * as moment from "moment";
import * as mTZ from "moment-timezone";

// Misc
import Highcharts from "highcharts";
import App from "./components/App.js";
import "./styles.css";
import "./custom.scss";

// Highcharts modules
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/pattern-fill")(Highcharts);
require("highcharts/modules/datagrouping")(Highcharts);

// Highcharts timezone fix
// https://github.com/highcharts/highcharts/issues/8661
window.moment = moment;
mTZ();

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
ReactDOM.render(<BrowserRouter basename={baseUrl}><App /></BrowserRouter>, rootElement);
