import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow
} from "@react-google-maps/api";
import LoadingOverlay from "./LoadingOverlay";

class StationSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStations: true,
      stations: [],
      regionStations: [],

      map: {
        show: props.onboarding ? true : false,
        center: {
          lat: -40.9006,
          lng: 174.886
        },
        zoom: 6,
        options: {
          streetViewControl: false
        }
      },

      infoWindow: {
        anchor: null,
        station: null
      }
    };
    this.map = null;
  }

  componentDidMount() {
    fetch(
      process.env.REACT_APP_METWATCH_URL +
        "index.php?pageID=api_stations_test&pageNO=stations"
    )
      .then(res => {
        if (res.ok) return res.json();
      })
      .then(json => {
        if (Array.isArray(json)) {
          // Sort stations by display order
          json.sort(function(x, y) {
            if (x.displayorder < y.displayorder) {
              return -1;
            }
            if (x.displayorder > y.displayorder) {
              return 1;
            }
            return 0;
          });

          // Group stations by region
          let regionStations = [];
          json.forEach(station => {
            if (
              regionStations.length === 0 ||
              regionStations[regionStations.length - 1].label !== station.region
            ) {
              regionStations.push({ label: station.region, options: [] });
            }

            regionStations[regionStations.length - 1].options.push({
              value: station.station_id,
              label: station.logger_name
            });
          });

          // Set the state
          this.setState({ stations: json, regionStations: regionStations });

          // Attempt to set the default station
          if(!this.props.selectedStation && !this.props.onboarding) {
            this.props.setStation(json.find(
              station => station.station_id === "LUN"
            ));
          }
        }
      })
      .finally(() => {
        this.setState({loadingStations: false});
      });
  }

  showMap() {
    this.setState({
      map: { ...this.state.map, show: true }
    });
  }
  closeMap() {
    this.setState({
      map: { ...this.state.map, show: false },
      infoWindow: {
        anchor: null,
        station: null
      }
    });
  }
  selectStation(station) {
    this.props.setStation(station);
    this.closeMap();
  }

  render() {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Select
            className="w-100 mr-1"
            value={{
              value: this.props.selectedStation
                ? this.props.selectedStation.station_id
                : null,
              label: this.props.selectedStation
                ? this.props.selectedStation.logger_name
                : null
            }}
            onChange={value =>
              this.props.setStation(
                this.state.stations.find(
                  station => station.station_id === value.value
                )
              )
            }
            options={this.state.regionStations}
            isLoading={this.state.loadingStations}
          />
          <Button
            className="ml-1"
            variant="outline-primary"
            onClick={this.showMap.bind(this)}
          >
            Map
          </Button>
        </div>
        <Modal
          show={this.state.map.show}
          onHide={this.closeMap.bind(this)}
          size="lg"
          centered
        >
          <Modal.Body>
            <LoadScript
              id="script-loader"
              googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}
            >
              <GoogleMap
                id="circle-example"
                onLoad={map => {
                  this.map = map;
                }}
                mapContainerStyle={{
                  height: "90vh",
                  width: "100%"
                }}
                zoom={this.state.map.zoom}
                center={this.state.map.center}
                options={this.state.map.options}
              >
                {this.state.stations.map((station, i) => {
                  let clickStation = marker => {
                    // I'm not sure why we have to do this, but if we don't set the anchor to null first
                    // then the info window doesn't move.
                    this.setState(
                      { infoWindow: { anchor: null, station: null } },
                      () => {
                        this.map.panTo({lat: parseFloat(station.latitude), lng: parseFloat(station.longitude)});
                        this.map.setZoom(12);
                        this.setState({
                          infoWindow: { anchor: marker, station: station }
                        });
                      }
                    );
                  };

                  return (
                    <Marker
                      key={i}
                      onLoad={marker => {
                        if (
                          this.props.selectedStation &&
                          station.station_id ===
                            this.props.selectedStation.station_id
                        )
                          clickStation(marker);
                      }}
                      icon={{
                        path:
                          "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
                        fillColor:
                          this.props.selectedStation &&
                          this.props.selectedStation.station_id ===
                            station.station_id
                            ? "#fff300"
                            : "#ff000d",
                        fillOpacity: 1,
                        strokeColor: "#000",
                        strokeWeight: 2,
                        scale: 1
                      }}
                      position={{
                        lat: parseFloat(station.latitude),
                        lng: parseFloat(station.longitude)
                      }}
                      onClick={function() {
                        clickStation(this);
                      }}
                    />
                  );
                })}
                {this.state.infoWindow.station ? (
                  <InfoWindow
                    anchor={this.state.infoWindow.anchor}
                    onCloseClick={() => {
                      this.setState({
                        infoWindow: { anchor: null, station: null }
                      });
                    }}
                  >
                    <div className="text-center">
                      <span className="h6">{this.state.infoWindow.station.logger_name}</span>
                      <br />
                      {this.props.selectedStation &&
                      this.state.infoWindow.station.station_id ===
                        this.props.selectedStation.station_id ? null : (
                        <Button
                          variant="primary"
                          size="sm"
                          className="mb-1 mt-3"
                          onClick={() => {
                            this.selectStation(this.state.infoWindow.station);
                          }}
                        >
                          Use This Station
                        </Button>
                      )}
                    </div>
                  </InfoWindow>
                ) : null}
              </GoogleMap>
            </LoadScript>
            {this.state.loadingStations ? <LoadingOverlay /> : null}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default StationSelect;
