// Libraries
import React, { Component } from "react";
import { withRouter } from 'react-router';
import moment from "moment";
import Cookies from "js-cookie";
import URI from "urijs";

// Component libraries
import { Container, Row, Col, Alert } from "react-bootstrap";

// Components
import Header from "./Header/Header.js";
import Footer from "./Footer/Footer.js";
import { setupSynchronization } from "./Charts/Chart.js";
import RainfallTotalsChart from "./Charts/RainfallTotalsChart.js";
import RainfallAccumulationChart from "./Charts/RainfallAccumulationChart.js";
import SoilTempChart from "./Charts/SoilTempChart.js";
import GDDAccumulationChart from "./Charts/GDDAccumulationChart.js";
import EtChart from "./Charts/EtChart.js";
import EtAccumulationChart from "./Charts/EtAccumulationChart.js";
import SolarRadiationAccumulationChart from "./Charts/SolarRadiationAccumulationChart.js";
import SolarRadiationChart from "./Charts/SolarRadiationChart.js";
import CenterSpinner from "./CenterSpinner.js";

import "./WeatherTool.css";

class WeatherTool extends Component {
  constructor(props) {
    super(props);

    let savedStation = this.getSavedStation();

    let uri = new URI(this.props.location.search);

    let now = moment();
    let startYear = now.month() >= 8 ? now.year() : now.year() - 1;

    this.state = {
      loadingDefaultStation: savedStation ? false : uri.hasQuery('onboarding') ? false : true,

      selectedStation: savedStation ? savedStation : null,
      seasonStart: moment({ year: startYear, month: 8, day: 1 }),
      seasonStop: moment({ year: startYear + 1, month: 7, day: 31 }),

      graphOptions: {
        thisYearColor: "#6461F9",
        lastYearColor: "#C4C4C4",
        seasonalAverageColor: "#0ceb00"
      }
    };
  }

  componentDidMount() {
    setupSynchronization("page-content");
  }

  getSavedStation() {
    // If the onboarding query string parameter is supplied then we want to set the selected station to null
    let uri = new URI(this.props.location.search);
    if(uri.hasQuery('onboarding')) {
      return null;
    }
    
    // Otherwise attempt to remember the station
    else {
      let savedStation = Cookies.get("station");
      if (savedStation) {
        savedStation = JSON.parse(savedStation);
        if(
          !savedStation.station_id ||
          !savedStation.logger_name ||
          !savedStation.region ||
          !savedStation.latitude ||
          !savedStation.longitude
        ) savedStation = null;
      }
  
      return savedStation;
    }
  }

  // Used by the StationSelect as the onChange function
  setStation(station) {
    this.setState({ selectedStation: station, loadingDefaultStation: false });
    if (station) {
      // Only extract needed information because of size constraints
      Cookies.set(
        "station",
        {
          station_id: station.station_id,
          logger_name: station.logger_name,
          region: station.region,
          latitude: station.latitude,
          longitude: station.longitude
        },
        {
          expires: 365
        }
      );
    } else {
      Cookies.remove("station");
    }
  }

  render() {
    let uri = new URI(this.props.location.search);

    let graphProps = {
      stationId: this.state.selectedStation
        ? this.state.selectedStation.station_id
        : null,
      seasonStart: this.state.seasonStart,
      seasonStop: this.state.seasonStop,
      graphOptions: this.state.graphOptions
    };

    return (
      <>
        {/* Header */}
        <Header
          setStation={this.setStation.bind(this)}
          selectedStation={this.state.selectedStation}
          onboarding={uri.hasQuery('onboarding')}
        />

        <div id="page-content">
          {this.state.loadingDefaultStation ? (
            <>
              <CenterSpinner />
            </>
          ) : !this.state.selectedStation ? (
            <>
              {/* If the station is not set then display a bootstrap alert prompting the user to select a station */}
              <Container>
                <Alert className='my-3' variant="warning">Please select a station either from the drop down or the map in the top right.</Alert>
              </Container>
            </>
          ) : (
            <>
              {/* Rainfall */}
              <div style={{ backgroundColor: "#F8FBF7" }}>
                <Row className="justify-content-center text-center py-3 mx-1">
                  <strong>
                    Rainfall - {this.state.selectedStation.logger_name}
                  </strong>
                </Row>
              </div>

              {/* Graphs */}
              <Container className="py-3">
                <Row>
                  <Col>
                    <RainfallTotalsChart {...graphProps} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <RainfallAccumulationChart {...graphProps} />
                  </Col>
                </Row>
              </Container>

              {/* ET */}
              <div style={{ backgroundColor: "#F8FBF7" }}>
                <Row className="justify-content-center text-center py-3 mx-1">
                  <strong>ET - {this.state.selectedStation.logger_name}</strong>
                </Row>
              </div>

              {/* Graphs */}
              <Container className="py-3">
                <Row>
                  <Col>
                    <EtChart {...graphProps} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EtAccumulationChart {...graphProps} />
                  </Col>
                </Row>
              </Container>

              {/* Solar Radiation */}
              <div style={{ backgroundColor: "#F8FBF7" }}>
                <Row className="justify-content-center text-center py-3 mx-1">
                  <strong>Solar Radiation - {this.state.selectedStation.logger_name}</strong>
                </Row>
              </div>

              {/* Graphs */}
              <Container className="py-3">
                <Row>
                  <Col>
                    <SolarRadiationChart {...graphProps} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SolarRadiationAccumulationChart {...graphProps} />
                  </Col>
                </Row>
              </Container>

              {/* 10cm Soil Temperature */}
              <div style={{ backgroundColor: "#F8FBF7" }}>
                <Row className="justify-content-center text-center py-3 mx-1">
                  <strong>
                    10cm Soil Temperature -{" "}
                    {this.state.selectedStation.logger_name}
                  </strong>
                </Row>
              </div>

              {/* Graphs */}
              <Container className="py-3">
                <Row>
                  <Col>
                    <SoilTempChart {...graphProps} />
                  </Col>
                </Row>
              </Container>

              {/* 10cm Soil Temperature */}
              <div style={{ backgroundColor: "#F8FBF7" }}>
                <Row className="justify-content-center text-center py-3 mx-1">
                  <strong>
                    Growing Degree Days -{" "}
                    {this.state.selectedStation.logger_name}
                  </strong>
                </Row>
              </div>

              {/* Graphs */}
              <Container className="py-3">
                <Row>
                  <Col>
                    <GDDAccumulationChart {...graphProps} />
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </div>
        <Footer />
      </>
    );
  }
}

export default withRouter(WeatherTool);
