import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div id="footer" className="bg-light p-5 text-center">
        Weather Summary Tool prepared for FAR by <a href="https://hortplus.com">HortPlus N.Z. Limited</a>.
      </div>
    );
  }
}