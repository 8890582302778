import React, { Component } from "react";

import WeatherTool from "./WeatherTool.js";

export default class App extends Component {
  render() {
    return (
      <WeatherTool />
    );
  }
}