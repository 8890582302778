import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import merge from "deepmerge";
import moment from "moment";

export default class Chart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          plotBackgroundColor: "rgb(255,255,255)"
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: true,
          verticalAlign: "top",
          layout: "horizontal"
        },
        time: {
          timezone: "Pacific/Auckland"
        },
        rangeSelector: {
          enabled: false
        },
        navigator: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        tooltip: {
          split: false,
          shared: true,
          positioner: this.tooltipPositioner,
          shape: "square",
          borderColor: "#AAA"
        },
        xAxis: {
          type: "datetime",
          crosshair: true
        },
        plotOptions: {
          series: {
            pointInterval: 86400000,
            tooltip: {
              valueDecimals: 1
            },
            marker: {
              enabled: false,
              symbol: "cirlce"
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            dataGrouping: {
              forced: true,
              units: [["week", [1]]],
              dateTimeLabelFormats: {
                week: ['Week from %A, %b %e']
              }
            }
          }
        }
      }
    };

    this.chartRef = null;
  }

  componentDidUpdate() {
    if(this.props.noData === false && this.props.failed === false) {
      if (this.props.loading) this.chartRef.showLoading();
      else this.chartRef.hideLoading();
    }
  }

  tooltipPositioner(labelWidth, labelHeight, point) {
    // Attempt to float the tooltip to the left of the crosshair
    let x = point.plotX - this.chart.plotLeft - labelWidth / 2 + 30;
    if (x > 40) return { x: x, y: this.chart.plotTop + 10 };

    // If the tooltip was off the chart attempt to float the tooltip to the right of the crosshair
    x = point.plotX + this.chart.plotLeft + 10;
    if (this.chart.plotWidth - x > labelWidth)
      return { x: x, y: this.chart.plotTop + 10 };

    // If the tooltip was off the chart float the tooltip in the middle of the chart
    return {
      x: this.chart.chartWidth / 2 - labelWidth / 2,
      y: this.chart.plotTop + 10
    };
  }

  storeChart(chart) {
    this.chartRef = chart;
    this.chartRef.showLoading();
  }

  render() {
    if (this.props.failed) {
      return <Alert variant="danger">Sorry something went wrong loading {this.props.options.title.text}</Alert>;
    } else if(this.props.noData) {
      return <Alert variant="warning">{this.props.options.title.text} has no data</Alert>;
    } else {
      return (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="stockChart"
          options={merge(this.state.options, this.props.options)}
          callback={this.storeChart.bind(this)}
        />
      );
    }
  }
}

export function selectColor(year, seasonStart, graphOptions) {
  if(parseInt(year) === seasonStart.year() && graphOptions.thisYearColor) return graphOptions.thisYearColor;
  else if(year === "seasonalAverages" && graphOptions.seasonalAverageColor) return graphOptions.seasonalAverageColor;
  else if(graphOptions.lastYearColor) return graphOptions.lastYearColor;
  else return null;
}

export function seriesName(year) {
  return year === "seasonalAverages" ? "Seasonal Average" : year;
}

export function formatlabel(label, value, color, width = 150) {
  if (value !== "")
    return (
      '<div style="width: ' +
      width +
      'px;"><div style="float: left;"><span style="color:' +
      color +
      '">\u25CF</span> ' +
      label +
      ': </div><div style="float: right;"><b>' +
      value +
      "</b></div></div><br>"
    );
}

export function formatMissing(
  start_time,
  missing,
  min = -1,
  max = 1,
  axis = 0,
  threshold = 0
) {
  return {
    name: "Missing",
    type: "area",
    step: "center",
    threshold: threshold,
    boostThreshold: 0,
    showInLegend: false,
    color: "rgb(255,0,0)",
    lineWidth: 1,
    fillColor: {
      pattern: {
        path: {
          d: "M 0 0 L 20 20 M 19 -1 L 21 1 M -1 19 L 1 21",
          strokeWidth: 1
        },
        width: 20,
        height: 20,
        color: "rgba(255,0,0,0.5)"
      }
    },
    data: missing.map(function(x) {
      return x === 0 ? min : max;
    }),
    yAxis: axis,
    pointStart: new Date(start_time).getTime(),
    tooltip: {
      pointFormatter: function() {
        if (this.y > 0) {
          return formatlabel(this.series.name, "True", this.color);
        }
      }
    }
  };
}

export function setupSynchronization(container) {
  /**
   * In order to synchronize tooltips and crosshairs, override the
   * built-in events with handlers defined on the parent element.
   */
  ['mousemove', 'touchmove', 'touchstart'].forEach(function (eventType) {
    document.getElementById(container).addEventListener(
      eventType,
      function (e) {
        var chart, event;

        for (let i = 0; i < Highcharts.charts.length; i = i + 1) {
          chart = Highcharts.charts[i];
          if(!chart) continue;
          if(chart.renderTo.id === "OnionDownyGraph") continue;
          // Find coordinates within the chart
          event = chart.pointer.normalize(e);

          // Find all points
          let points = [];
          let max = chart.series.length;
          if(chart.renderTo.id === "DailyEventsGraph") max = 1;
          for (let x = 0; x < max; x++) {
            let point = chart.series[x].searchPoint(event, true);
            if (point) points.push(point);
          }


          // Find all different x values
          let xValues = [];
          for (let x = 0; x < points.length; x++) {
            if (xValues.indexOf(points[x].x) < 0) xValues.push(points[x].x);
          }

          // Find closest x value
          let xPos = chart.axes[0].toValue(event.chartX);
          let closest = xValues[0];
          for (let x = 1; x < xValues.length; x++) {
            let diff1 = Math.abs(xValues[x] - xPos);
            let diff2 = Math.abs(closest - xPos);
            if (diff1 < diff2) closest = xValues[x];
          }


          // Highlight closest point
          for (let x = 0; x < points.length; x++) {
            if (points[x].x === closest) {
              if(points[x].series.options.enableMouseTracking !== false) points[x].onMouseOver();
              break;
            }
          }
        }
      }
    );
  });

  /**
   * Override the reset function, we don't need to hide the tooltips and
   * crosshairs.
   */
  Highcharts.Pointer.prototype.reset = function () {
    for (let i = 0; i < Highcharts.charts.length; i++) {
      let chart = Highcharts.charts[i];
      if(!chart) continue;
      if(chart.renderTo.id === "OnionDownyGraph") continue;
      chart.tooltip.hide();
      chart.xAxis[0].hideCrosshair();
      for(let series = 0; series < chart.series.length; series++) {
        chart.series[series].setState();
      }
      if(chart.hoverPoints) {
        for (let point = 0; point < chart.hoverPoints.length; point++) {
          let point_obj = chart.hoverPoints[point];
          if (point_obj) point_obj.setState();
        }
      }
    }
  };
}

export function approximationMax(data) {
  let startIndex = this.cropStart + this.dataGroupInfo.start;
  let start = moment(this.options.pointStart + startIndex * this.options.pointInterval);
  let now = moment();

  // Don't display data in the current week or in the future
  if(parseInt(this.options.name) === now.year() && start.week() >= now.week()) return null;

  return Math.max(...data);
}

export function approximationAverage(data) {
  let startIndex = this.cropStart + this.dataGroupInfo.start;
  let start = moment(this.options.pointStart + startIndex * this.options.pointInterval);
  let now = moment();

  // Don't display data in the current week or in the future
  if(parseInt(this.options.name) === now.year() && start.week() >= now.week()) return null;
  else if(data.length === 0) return null;

  return data.reduce((accumulator, currentValue) => accumulator + currentValue)/data.length;
}

export function approximationSum(data) {
  let startIndex = this.cropStart + this.dataGroupInfo.start;
  let start = moment(this.options.pointStart + startIndex * this.options.pointInterval);
  let now = moment();

  // Don't display data in the current week or in the future
  if(parseInt(this.options.name) === now.year() && start.week() >= now.week()) return null;
  else if(data.length === 0) return null;

  return data.reduce((accumulator, currentValue) => accumulator + currentValue);
}